import * as React from "react";
import SocialBar from "../components/social/social-bar";
import Platern from "/src/assets/platern.svg"
import {Link} from "gatsby";

export default function EmailSubscriptionConfirmation() {

  return (
      <section
        className="mt-15vh mb-15vh motion-safe:animate-fade-in-1-5s text-center md:text-left">
        <div className="m-auto">
          <div
            className="m-auto md:inline-block w-full md:p-0 md:align-middle">
            <div className="align-middle">
              <div
                className="mb-10">
                <div>
                  <Link to="/">
                      <Platern
                        className="header__logo dark:header__logo m-auto mb-4"
                        alt="Platern"
                      />
                  </Link>
                  <h1 className="mb-4 text-primary text-3xl font-black leading-normal text-center md:leading-snug">Thanks for subscribing</h1>
                  {/*<p className="mb-10">In the UK, apps and websites authorised by <a className="text-primary" href="#">the FCA</a> help you to understand and control your finances with open banking.</p>*/}
                  {/*<p className="mb-6">We’re building a service to make fast bank transfers and read account data. Champion the customer experience by using our resources to connect directly to the open banking network.</p>*/}
                  <p className="mb-6 text-center">We’re excited you’re here!</p>
                  <p className="mb-6 text-center"><strong>You can close this page.</strong></p>
                  <p className="mb-6 text-center">You can also join us on other platforms:</p>
                  <div className="md:w-1/2 m-auto mb-20">
                    <SocialBar />
                  </div>
                  <div className="m-auto text-center mb-6">
                    <Link to="/" className="">Return to the main website</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )

}